export default defineComponent({
  props: {
    goalName: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    target: {
      type: String,
      default: ''
    },
    isPositive: {
      type: String,
      default: 'positive'
    }
  },
  setup: function setup() {}
});